import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../images/gotii_logo-Photoroom.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.gotii.in/api/v1/admin/login",
        { email, password },
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow", // Add API key here
          },
        }
      );
      localStorage.setItem("admin-token", response.data.token);
      toast.success(response.data.message, {
        autoClose: 2000,
      }); // Show success message

      // Redirect after showing the success message
      setTimeout(() => {
        navigate("/allusers"); // Redirect to dashboard
      }, 2000);
    } catch (error) {
      toast.error(error.response.data.message,{
        autoClose: 2000,
      });

      if (error && error.status === 403) {
        setTimeout(() => {
          navigate("/"); // Redirect to dashboard
        }, 2000);
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex justify-center items-center bg-cover bg-center bg-no-repeat bg-opacity-100 relative">
      {/* Added relative positioning */}
      <img
        src={logo} // Logo source
        alt="Company Logo"
        className="absolute top-5 left-5 w-40 h-auto" // Increased size from w-24 to w-32
        style={{ backgroundColor: "none" }} // Set background color to match the page
      />
      <div className="bg-slate-100 backdrop-blur-[2px] hover:backdrop-blur-lg backdrop-brightness-150 md:backdrop-filter-none rounded-xl p-10 shadow-lg w-[500px] h-[450px] border border-white/20 transition-transform duration-300 transform hover:scale-105 hover:shadow-xl hover:shadow-orange-500/50">
        {/* Glassmorphism effect */}
        <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-blue-700 text-center mb-6 h-[50px]">
          Gotii Admin Login
        </h2>
        <form onSubmit={handleLogin} className="space-y-8">
          {/* Reduced space between inputs */}
          <div>
            <label className="block text-2xl font-bold text-green-500 mb-1">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full p-3 rounded-lg bg-white text-black placeholder-black/80 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow shadow-yellow-200/100 transition duration-200" // Added shadow effect
              required
            />
          </div>
          <div>
            <label className="block text-2xl font-bold text-green-500 mb-1">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="***********"
              className="w-full p-3 rounded-lg bg-white text-black placeholder-black/80 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow shadow-yellow-200/100 transition duration-200" // Added shadow effect
              required
            />
          </div>
          <button
            type="submit"
            className="w-full p-4 rounded-lg bg-gradient-to-r from-blue-500 to-pink-500 text-white font-bold hover:opacity-90 transition-transform duration-300 shadow-lg hover:scale-105" // Shadow and hover effects
          >
            LOGIN
          </button>
        </form>
      </div>
      {/* Toast container for notifications */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        draggable
        theme="dark"
      />
    </div>
  );
};

export default Login;