import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const WithdrawalRejectUpdateModal = ({ withdrawal, onClose }) => {
  const [remark, setRemark] = useState(""); // State to store entered remark
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("admin-token");
      const response = await axios.post(
        `https://api.gotii.in/api/v1/admin/rejected-withdrawal`,
        {
          id: withdrawal._id,
          remark: remark,
        },
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
          },
        }
      );

      toast.success(response.data.message, {
        autoClose: 2000,
      }); // Show success message

      // Redirect to dashboard after a short delay
      setTimeout(() => {
        onClose(); // Close the modal
        navigate("/pendingwithdrawals"); // Navigate to pending withdrawals
        window.location.reload(); // Force reload the dashboard page to refresh data
      }, 2000);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred", {
        autoClose: 2000,
      });

      if (error && error.response?.status === 403) {
        setTimeout(() => {
          onClose();
          navigate("/"); // Redirect to login if unauthorized
        }, 2000);
      }
      onClose();
    }
  };

  return (
    <div
      className="rounded-lg p-6 max-w-lg mx-auto"
    >
      <h2 className="modal-header text-md text-black font-semibold mb-4 text-center">
        Update Status for {withdrawal._id}
      </h2>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <input
          type="text"
          placeholder="Give remark"
          className="add-coin-input mb-4 p-2 border text-black border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500"
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          required
        />
        <div className="button-container flex justify-between">
          <button
            type="submit"
            className="modal-button update-button bg-green-500 text-white rounded-md py-2 px-4 transition duration-200 hover:bg-green-600 w-full mr-2"
          >
            Update
          </button>
          <button
            type="button"
            className="modal-button cancel-button bg-red-500 text-white rounded-md py-2 px-4 transition duration-200 hover:bg-red-600 w-full"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default WithdrawalRejectUpdateModal;
