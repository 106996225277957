import React from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../images/gotii_logo-Photoroom.png";
import { ToastContainer, toast } from "react-toastify";

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const token = localStorage.getItem("admin-token");
    try {
      const response = await axios.get(
        "https://api.gotii.in/api/v1/admin/logout",
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
          },
        }
      );
      localStorage.removeItem("admin-token");
      toast.success(response.data.message, {
        autoClose: 2000,
      }); // Show success message

      // Redirect after showing the success message
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 2000,
      });

      if (error && error.status === 403) {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    }
  };

  return (
    <div className="w-64 bg-white text-black p-4 flex flex-col h-screen">
      <div className="mb-8">
        <img src={logo} alt="Company Logo" className="w-40 h-auto" />
      </div>
      <ul className="space-y-2 flex-grow">
        <li>
          <Link
            to="/allusers"
            className="block text-green-500 text-left font-bold p-4 transition duration-200 hover:bg-green-100 hover:text-black hover:shadow-lg rounded-lg"
          >
            All User
          </Link>
        </li>
        <li>
          <Link
            to="/usersummary"
            className="block text-green-500 text-left font-bold p-4 transition duration-200 hover:bg-green-100 hover:text-black hover:shadow-lg rounded-lg"
          >
            User Summary
          </Link>
        </li>
        <li>
          <Link
            to="/pendingwithdrawals"
            className="block text-green-500 text-left font-bold p-4 transition duration-200 hover:bg-green-100 hover:text-black hover:shadow-lg rounded-lg"
          >
            Pending Withdrawal
          </Link>
        </li>
        <li>
          <Link
            to="/acceptedwithdrawals"
            className="block text-green-500 text-left font-bold p-4 transition duration-200 hover:bg-green-100 hover:text-black hover:shadow-lg rounded-lg"
          >
            Accepted Withdrawal
          </Link>
        </li>
        <li>
          <Link
            to="/rejectedwithdrawals"
            className="block text-green-500 text-left font-bold p-4 transition duration-200 hover:bg-green-100 hover:text-black hover:shadow-lg rounded-lg"
          >
            Rejected Withdrawal
          </Link>
        </li>
        <li>
          <Link
            to="/getAllAdd"
            className="block text-green-500 text-left font-bold p-4 transition duration-200 hover:bg-green-100 hover:text-black hover:shadow-lg rounded-lg"
          >
            All Advertisement
          </Link>
        </li>
      </ul>

      <button
        onClick={handleLogout}
        className="bg-red-500 text-white mt-4 w-full p-4 text-center font-bold transition duration-200 hover:bg-red-600 hover:text-black hover:scale-105 rounded-lg mb-8 shadow-lg"
      >
        Logout
      </button>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        draggable
        theme="dark"
      />
    </div>
  );
};

export default Sidebar;
