import React from "react";
import Login from "./components/Login";
import AllUsers from "./components/AllUsers";
import { Routes, Route } from "react-router-dom";
import PendingWithdrawal from "./components/pendingWithdrawal";
import AcceptedWithdrawal from "./components/AcceptedWithdrawal";
import RejectedWithdrawal from "./components/RejectedWithdrawal";
import GetAllAdds from "./components/GetAllAdds";
import UserSummary from "./components/UserSummary";

const App = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/allusers" element={<AllUsers />} />
    <Route path="/usersummary" element={<UserSummary />} />
    <Route path="/pendingwithdrawals" element={<PendingWithdrawal />} />
    <Route path="/acceptedwithdrawals" element={<AcceptedWithdrawal />} />
    <Route path="/rejectedwithdrawals" element={<RejectedWithdrawal />} />
    <Route path="/getAllAdd" element={<GetAllAdds />} />
  </Routes>
);

export default App;
