import React, { useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const UserSummary = () => {
  const navigate = useNavigate();

  const [mobile, setMobile] = useState("");
  const [user, setUser] = useState(null);
  const [wallet, setWallet] = useState([]);

  // Function to handle API call for user summary
  const fetchUserDetails = async () => {
    if (mobile.length !== 10 || !/^\d+$/.test(mobile)) {
      toast.error("Please enter a valid 10-digit mobile number", {
        autoClose: 2000,
      });
      return;
    }

    try {
      const token = localStorage.getItem("admin-token");
      const response = await axios.get(
        `https://api.gotii.in/api/v1/admin/user/summary/${mobile}`,
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
          },
        }
      );
      setUser(response.data.summary.user);
      setWallet(response.data.summary.wallet);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching details", {
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100 text-black">
      <Sidebar />
      <div className="w-full p-4 relative">
        <h1 className="text-2xl md:text-4xl font-bold mb-4 text-center p-3">
          User Details
        </h1>

        <div className="mb-4 flex justify-center">
          <input
            type="text"
            maxLength={10}
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className="bg-gray-100 text-black text-2xl rounded-lg p-2 mr-2"
            placeholder="Enter 10-digit mobile number"
          />
          <button
            onClick={fetchUserDetails}
            className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-800 transition duration-200"
          >
            Get Details
          </button>
        </div>

        {user && (
          <>
            <h2 className="text-xl font-bold mb-2">User Information</h2>
            <table className="w-full table-fixed bg-black rounded-lg shadow-lg border border-black">
              <thead>
                <tr className="bg-orange-700 text-white">
                  <th className="p-4 text-left">Mobile</th>
                  <th className="p-4 text-left">UID</th>
                  <th className="p-4 text-left">Name</th>
                  <th className="p-4 text-left">Coins</th>
                  <th className="p-4 text-left">UPI id</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white text-black">
                  <td className="p-4 border-b border-gray-600 text-left">
                    {user.mobile}
                  </td>
                  <td className="p-4 border-b border-gray-600 text-left">
                    {user.uid}
                  </td>
                  <td className="p-4 border-b border-gray-600 text-left">
                    {user.name}
                  </td>
                  <td className="p-4 border-b border-gray-600 text-left">
                    {user.coins}
                  </td>
                  <td className="p-4 border-b border-gray-600 text-left">
                    {JSON.stringify(user.paymentCredentials.upi)}
                  </td>
                </tr>
              </tbody>
            </table>

            <h2 className="text-xl font-bold mt-6 mb-2">Wallet Information</h2>
            <table className="w-full table-fixed bg-black rounded-lg shadow-lg border border-black">
              <thead>
                <tr className="bg-orange-700 text-white">
                  <th className="p-4 text-left">Type</th>
                  <th className="p-4 text-left">Amount</th>
                  <th className="p-4 text-left">Available Coins</th>
                  <th className="p-4 text-left">Status</th>
                  <th className="p-4 text-left">Details</th>
                </tr>
              </thead>
              <tbody>
                {wallet.map((entry, index) => (
                  <tr
                    key={entry._id}
                    className={`${
                      index % 2 === 0
                        ? "bg-white text-black"
                        : "bg-slate-200 text-black"
                    }`}
                  >
                    <td className="p-4 border-b border-gray-600 text-left">
                      {entry.type}
                    </td>
                    <td className="p-4 border-b border-gray-600 text-left">
                      {entry.amount}
                    </td>
                    <td className="p-4 border-b border-gray-600 text-left">
                      {entry.availableCoins}
                    </td>
                    <td className="p-4 border-b border-gray-600 text-left">
                      {entry.status}
                    </td>
                    <td className="p-4 border-b border-gray-600 text-left">
                      {entry.details}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover={false}
          draggable={false}
          theme="dark"
        />
      </div>
    </div>
  );
};

export default UserSummary;
