import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement("#root");

const GetAllAdds = () => {
  const navigate = useNavigate();
  const [adds, setAdds] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [newAdd, setNewAdd] = useState({
    title: "",
    type: "CAROUSAL",
    file: null,
  });

  const fetchAllAdds = async () => {
    try {
      const token = localStorage.getItem("admin-token");
      const response = await axios.get(
        `https://api.gotii.in/api/v1/admin/addvertisment`,
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
          },
        }
      );

      setAdds(response.data.data);
      setUserCount(response.data.data.length);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching all Adds", {
        autoClose: 2000,
      });

      if (error && error.response?.status === 403) {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    }
  };

  const deleteAdd = async (addId) => {
    try {
      const token = localStorage.getItem("admin-token");
      const response = await axios.post(
        `https://api.gotii.in/api/v1/admin/addvertisment/delete`,
        { id: addId },
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
          },
        }
      );

      toast.success(response.data.message || "Advert deleted successfully", {
        autoClose: 2000,
      });

      setTimeout(() => {
        navigate("/getAllAdd");
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error deleting advert", {
        autoClose: 2000,
      });
    }
  };

  const handleFileChange = (e) => {
    setNewAdd({ ...newAdd, file: e.target.files[0] });
  };

  const handleInputChange = (e) => {
    setNewAdd({ ...newAdd, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("admin-token");
      const formData = new FormData();
      formData.append("title", newAdd.title);
      formData.append("type", newAdd.type);
      formData.append("file", newAdd.file);

      await axios.post(
        "https://api.gotii.in/api/v1/admin/addvertisment/create",
        formData,
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Advert created successfully", {
        autoClose: 2000,
      });
      setTimeout(() => {
        setShowModal(false);
        navigate("/getAllAdd");
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error creating advert", {
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    fetchAllAdds();
  }, []);

  return (
    <div className="flex min-h-screen bg-white text-black">
      <Sidebar />
      <div className="w-full p-4 relative">
        <h1 className="text-4xl font-bold mb-4 text-center p-3">All Adds</h1>

        {/* Create Add Button */}
        <div
          className="absolute top-10 left-10 bg-green-600 text-white px-4 py-2 rounded-lg shadow-lg cursor-pointer flex items-center space-x-2 hover:bg-green-700 transition duration-200"
          onClick={() => setShowModal(true)}
        >
          <span>Create Add</span>
          <span className="text-2xl font-bold">+</span>
        </div>

        {/* Display total add count */}
        <div className="absolute top-10 right-10 bg-green-600 text-white px-4 py-2 rounded-lg shadow-lg">
          Total Adds: {userCount}
        </div>

        <div className="mb-4">
          <label className="mr-2"></label>
          <select className="bg-white text-white"></select>
        </div>

        <div className="w-full overflow-x-auto">
          <table className="w-full table-fixed bg-black rounded-lg shadow-lg border border-black">
            <thead>
              <tr className="bg-orange-700 text-white">
                <th className="w-1/6 p-4 text-left">Type</th>
                <th className="w-1/4 p-4 text-left">Title</th>
                <th className="w-1/4 p-4 text-left">ImageLink</th>
                <th className="w-1/6 p-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody>
              {adds &&
                adds.length > 0 &&
                adds.map((add, index) => (
                  <tr
                    key={add._id}
                    className={`${
                      index % 2 === 0
                        ? "bg-white text-black"
                        : "bg-slate-200 text-black"
                    } hover:bg-black hover:text-white transition duration-200`}
                  >
                    <td className="w-1/6 p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                      {add.type}
                    </td>
                    <td className="w-1/4 p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                      {add.title}
                    </td>
                    <td className="w-1/4 p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                      <a
                        href={add.imageLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 font-medium underline hover:no-underline transition-colors duration-300"
                      >
                        {add.imageLink}
                      </a>
                    </td>
                    <td className="w-1/6 p-4 border-b border-gray-600 text-left">
                      <button
                        onClick={() => deleteAdd(add._id)}
                        className="bg-red-500 text-black px-6 py-1 rounded-lg text-sm hover:scale-105 transition duration-200"
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Styled Modal for creating add */}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className="modal-content bg-white p-8 rounded-lg shadow-2xl max-w-lg mx-auto"
        overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center"
      >
        <h2 className="text-2xl font-bold mb-6 text-center text-black">
          Create Add
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2 text-white">Title</label>
            <input
              type="text"
              name="title"
              value={newAdd.title}
              onChange={handleInputChange}
              className="w-full p-3 bg-white text-black rounded-lg border border-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
              placeholder="Enter title"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-white">Type</label>
            <select
              name="type"
              value={newAdd.type}
              onChange={handleInputChange}
              className="w-full p-3 bg-white text-black rounded-lg border border-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
            >
              <option value="CAROUSAL">CAROUSAL</option>
              <option value="BANNER">BANNER</option>
            </select>
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-white">Image</label>
            <input
              type="file"
              name="file"
              onChange={handleFileChange}
              className="w-full p-2 bg-white text-black rounded-lg border border-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
              required
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-800 transition duration-300"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        draggable
        theme="dark"
      />
    </div>
  );
};

export default GetAllAdds;
