import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddCoinModal = ({ user, onClose }) => {
  const [coinAmount, setCoinAmount] = useState(""); // State to store entered coin amount
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Determine the type based on whether the coin amount is positive or negative
    const type = coinAmount > 0 ? "INCREMENT" : "DECREMENT";

    try {
      const token = localStorage.getItem("admin-token");
      const response = await axios.post(
        `https://api.gotii.in/api/v1/admin/users/update-coin`,
        {
          mobile: user.mobile,
          type: type, // Dynamic type
          coins: Math.abs(coinAmount), // Send the absolute value of coins
        },
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
          },
        }
      );

      toast.success(response.data.message, {
        autoClose: 2000,
      }); // Show success message

      // Redirect to dashboard after a short delay
      setTimeout(() => {
        onClose(); // Close the modal
        navigate("/allusers"); // Navigate to dashboard
        window.location.reload(); // Force reload the dashboard page to refresh data
      }, 2000);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred",{
        autoClose: 2000,
      });

      if (error && error.response?.status === 403) {
        setTimeout(() => {
          onClose();
          navigate("/"); // Redirect to login if unauthorized
        }, 2000);
      }
      onClose();
    }
  };

  return (
    <div className="rounded-lg p-6 max-w-lg mx-auto">
      <h2 className="text-black text-2xl font-semibold mb-4 text-center">
        Update Coins for {user.name}
      </h2>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <input
          type="number"
          placeholder="Enter amount"
          className="p-3 rounded-lg border border-gray-300 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={coinAmount}
          onChange={(e) => setCoinAmount(Number(e.target.value))} // Set coin amount from user input
          required
        />
        <div className="flex justify-between space-x-2">
          <button
            type="submit"
            className="flex-grow py-2 px-4 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 focus:outline-none transition duration-200"
          >
            Update
          </button>
          <button
            type="button"
            className="flex-grow py-2 px-4 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700 focus:outline-none transition duration-200"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCoinModal;
