import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal"; // Import the modal package
import WithdrawalAcceptUpdateModal from "./WithdrawalAcceptUpdateModal";
import WithdrawalRejectUpdateModal from "./WithdrawalRejectUpdateModal";

Modal.setAppElement("#root"); // Set the app element for accessibility

const PendingWithdrawal = () => {
  const navigate = useNavigate();

  const [withdrawals, setWithdrawals] = useState([]);
  const [totalWithdrawalsCount, setTotalWithdrawalsCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [userCount, setUserCount] = useState(0); // State to hold total user count
  const [sortOption, setSortOption] = useState("none"); // Default to "None"

  // Fetch withdrawals with pagination and sorting
  const fetchPendingWithdrawals = async (page, limit, sort) => {
    try {
      const token = localStorage.getItem("admin-token");
      const response = await axios.get(
        `https://api.gotii.in/api/v1/admin/created-withdrawal?page=${page}&limit=${limit}`,
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
          },
        }
      );

      let fetchedWithdrawals = response.data.created;

      // Apply sorting based on selected option
      if (sort === "amount_asc") {
        fetchedWithdrawals.sort((a, b) => a.amount - b.amount);
      } else if (sort === "amount_desc") {
        fetchedWithdrawals.sort((a, b) => b.amount - a.amount);
      }
      // No sorting for "none"

      setWithdrawals(fetchedWithdrawals);
      setTotalWithdrawalsCount(response.data.total);
      setUserCount(response.data.total);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error fetching pending withdrawals",
        {
          autoClose: 2000,
        }
      );

      if (error && error.response?.status === 403) {
        setTimeout(() => {
          navigate("/"); // Redirect to login if unauthorized
        }, 2000);
      }
    }
  };

  useEffect(() => {
    fetchPendingWithdrawals(currentPage, limit, sortOption);
  }, [currentPage, limit, sortOption]);

  const openModal = (withdrawal, type) => {
    setSelectedUser(withdrawal);
    setModalType(type); // Set the modal type to either "accept" or "reject"
    setShowModal(true); // Open the modal
  };

  const totalPages = Math.ceil(totalWithdrawalsCount / limit);

  return (
    <div className="flex min-h-screen bg-gray-100 text-black">
      <Sidebar />
      <div className="w-full p-4 relative">
        <h1 className="text-4xl font-bold mb-4 text-center p-3">
          Pending Withdrawal
        </h1>

        {/* Display total user count in top right corner with adjusted margin and color */}
        <div className="absolute top-10 right-5 md:right-10 bg-yellow-600 text-white px-4 py-2 rounded-lg shadow-lg">
          Total Pending: {userCount}
        </div>

        <div className="mb-4 flex justify-between">
          <div>
            <label className="mr-2">Select limit </label>
            <select
              value={limit}
              onChange={(e) => {
                setLimit(Number(e.target.value));
                setCurrentPage(1);
              }}
              className="bg-gray-800 text-white rounded-lg p-2"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
              <option value={5000}>5000</option>
              <option value={10000}>10000</option>
            </select>
          </div>

          {/* Sort by Amount */}
          <div>
            <label className="mr-2">Sort by </label>
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="bg-gray-800 text-white rounded-lg p-2"
            >
              <option value="none">None</option>
              <option value="amount_desc">Amount desc</option>
              <option value="amount_asc">Amount asc</option>
            </select>
          </div>
        </div>

        <table className="table-auto w-full bg-black rounded-lg shadow-lg border border-black">
          <thead>
            <tr className="bg-orange-700 text-white">
              <th className="w-1/6 p-4 text-left">id</th>
              <th className="w-1/6 p-4 text-left">Mobile</th>
              <th className="w-1/6 p-4 text-left">Amount</th>
              <th className="w-1/6 p-4 text-left">Status</th>
              <th className="w-1/6 p-4 text-left">ImageLink</th>
              <th className="w-1/6 p-4 text-left">UPIid</th>
              <th className="w-1/6 p-4 text-left">Accept</th>
              <th className="w-1/6 p-4 text-left">Reject</th>
            </tr>
          </thead>
          <tbody>
            {withdrawals &&
              withdrawals.length > 0 &&
              withdrawals.map((withdrawal, index) => (
                <tr
                  key={withdrawal._id}
                  className={`${
                    index % 2 === 0
                      ? "bg-white text-black"
                      : "bg-slate-200 text-black"
                  } hover:bg-black hover:text-white transition duration-200`}
                >
                  <td className="w-1/6 p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                    {withdrawal._id}
                  </td>
                  <td className="w-1/4 p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                    {withdrawal.mobile}
                  </td>
                  <td className="w-1/4 p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                    {withdrawal.amount}
                  </td>
                  <td className="w-1/6 p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                    {withdrawal.status}
                  </td>
                  <td className="w-1/6 p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                    {withdrawal.imageLink}
                  </td>
                  <td className="w-1/6 p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                    {JSON.stringify(withdrawal.paymentCredentials?.upi)}
                  </td>
                  <td className="w-1/6 p-4 border-b border-gray-600 text-left">
                    <button
                      onClick={() => openModal(withdrawal, "accept")}
                      className="bg-green-500 text-black px-6 py-1 rounded-lg text-sm hover:scale-105 transition duration-200"
                    >
                      accept
                    </button>
                  </td>
                  <td className="w-1/6 p-4 border-b border-gray-600 text-left">
                    <button
                      onClick={() => openModal(withdrawal, "reject")}
                      className="bg-orange-500 text-black px-6 py-1 rounded-lg text-sm hover:scale-105 transition duration-200"
                    >
                      reject
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={`mx-1 px-3 py-1 rounded-lg ${
                currentPage === index + 1
                  ? "bg-yellow-600 text-white"
                  : "bg-yellow-700 text-white"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>

        {/* Modals for Accept/Reject */}
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          contentLabel="Withdrawal Modal"
          className="modal-content bg-white p-8 rounded-lg shadow-2xl max-w-lg mx-auto"
          overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center"
        >
          {modalType === "accept" && selectedUser && (
            <WithdrawalAcceptUpdateModal
              withdrawal={selectedUser}
              onClose={() => setShowModal(false)}
            />
          )}
          {modalType === "reject" && selectedUser && (
            <WithdrawalRejectUpdateModal
              withdrawal={selectedUser}
              onClose={() => setShowModal(false)}
            />
          )}
        </Modal>

        <ToastContainer />
      </div>
    </div>
  );
};

export default PendingWithdrawal;
