import React, { useState, useEffect } from "react";
import axios from "axios";
import AddCoinModal from "./AddCoinModal";
import Sidebar from "./Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement("#root");

const AllUsers = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [totalUsers, setTotalUsers] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [sortOption, setSortOption] = useState(""); // Sorting state

  const fetchUserCount = async () => {
    try {
      const token = localStorage.getItem("admin-token");
      const response = await axios.get(
        `https://api.gotii.in/api/v1/admin/users/length`,
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
          },
        }
      );
      setUserCount(response.data.count);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });

      if (error && error.response?.status === 403) {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    }
  };

  const fetchUsers = async (page, limit) => {
    try {
      const token = localStorage.getItem("admin-token");
      const response = await axios.get(
        `https://api.gotii.in/api/v1/admin/users?page=${page}&limit=${limit}`,
        {
          headers: {
            "B-API-KEY": "burst-coast-jack-thing-pot-cow",
            "admin-token": token,
          },
        }
      );

      let sortedUsers = response.data.users;
      if (sortOption === "coins_asc") {
        sortedUsers = sortedUsers.sort((a, b) => a.coins - b.coins);
      } else if (sortOption === "coins_desc") {
        sortedUsers = sortedUsers.sort((a, b) => b.coins - a.coins);
      } else if (sortOption === "name_asc") {
        sortedUsers = sortedUsers.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } else if (sortOption === "name_desc") {
        sortedUsers = sortedUsers.sort((a, b) =>
          b.name.localeCompare(a.name)
        );
      }

      setUsers(sortedUsers);
      setTotalUsers(response.data.total || response.data.users.length);

      fetchUserCount();
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching users", {
        autoClose: 2000,
      });

      if (error && error.response?.status === 403) {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    }
  };

  useEffect(() => {
    fetchUsers(currentPage, limit);
  }, [currentPage, limit, sortOption]);

  const openModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const totalPages = Math.ceil(totalUsers / limit);

  return (
    <div className="flex min-h-screen bg-gray-100 text-white">
      <Sidebar />
      <div className="w-full p-4 relative">
        <h1 className="text-2xl md:text-4xl font-bold mb-4 text-center p-3">
          User List
        </h1>

        {/* Total User Count */}
        <div className="absolute top-2 right-2 md:top-10 md:right-10 bg-green-600 text-white px-4 py-2 rounded-lg shadow-lg">
          Total Users: {userCount}
        </div>

        {/* Controls for limit and sort */}
        <div className="flex justify-between mb-4 items-center">
          <div>
            <label className="mr-2 text-black">Select limit </label>
            <select
              value={limit}
              onChange={(e) => {
                setLimit(Number(e.target.value));
                setCurrentPage(1);
              }}
              className="bg-gray-800 text-white rounded-lg p-2"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
              <option value={5000}>5000</option>
              <option value={10000}>10000</option>
            </select>
          </div>

          {/* Sort by Dropdown */}
          <div>
            <label className="mr-2 text-black">Sort by </label>
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="bg-gray-800 text-white rounded-lg p-2"
            >
              <option value="">None</option>
              <option value="coins_desc">Coins desc</option>
              <option value="coins_asc">Coins asc</option>
              <option value="name_desc">Name desc</option>
              <option value="name_asc">Name asc</option>
            </select>
          </div>
        </div>

        {/* User Table */}
        <div className="w-full overflow-x-auto">
          <table className="w-full table-fixed bg-black rounded-lg shadow-lg border border-black">
            <thead>
              <tr className="bg-orange-700 text-white">
                <th className="w-1/6 p-4 text-left">UID</th>
                <th className="w-1/4 p-4 text-left">Name</th>
                <th className="w-1/4 p-4 text-left">Mobile</th>
                <th className="w-1/6 p-4 text-left">Coins</th>
                <th className="w-1/6 p-4 text-left">UPI Id</th>
                <th className="w-1/6 p-4 text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.length > 0 &&
                users.map((user, index) => (
                  <tr
                    key={user._id}
                    className={`${
                      index % 2 === 0
                        ? "bg-white text-black"
                        : "bg-slate-200 text-black"
                    } hover:bg-black hover:text-white transition duration-200`}
                  >
                    <td className="p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                      {user.uid}
                    </td>
                    <td className="p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                      {user.name}
                    </td>
                    <td className="p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                      {user.mobile}
                    </td>
                    <td className="p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                      {user.coins}
                    </td>
                    <td className="p-4 border-b border-gray-600 text-sm text-left overflow-hidden text-ellipsis whitespace-nowrap">
                      {JSON.stringify(user.paymentCredentials.upi)}
                    </td>
                    <td className="p-4 border-b border-gray-600 text-left">
                      <button
                        onClick={() => openModal(user)}
                        className="bg-yellow-300 text-black px-6 py-1 rounded-lg text-sm hover:scale-105 transition duration-200"
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={`mx-1 px-3 py-1 rounded-lg ${
                currentPage === index + 1
                  ? "bg-green-600 text-white"
                  : "bg-green-600 text-white"
              } hover:bg-emerald-300 transition duration-200`}
            >
              {index + 1}
            </button>
          ))}
        </div>

        {/* Modal for AddCoin */}
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          className="modal"
          overlayClassName="overlay"
          closeTimeoutMS={200}
          shouldCloseOnOverlayClick={true}
        >
          <AddCoinModal user={selectedUser} onClose={() => setShowModal(false)} />
        </Modal>

        <ToastContainer />
      </div>
    </div>
  );
};

export default AllUsers;
